import React from 'react';
import logo from './logo.svg';
import './App.css';
import USMap from './all-counties';

import ReactGA from 'react-ga';

import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';

//https://geo.fcc.gov/api/census/#!/block/get_block_find to find fips by lat/lon
import useGeolocation from 'react-hook-geolocation'


import Button from '@material-ui/core/Button';
import MyLocationIcon from '@material-ui/icons/MyLocation';

import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
  isMobileOnly
} from "react-device-detect";


function App() 
{

  if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
    // dev code
  } 
  else 
  {
      // production code
    ReactGA.initialize('UA-163479772-1');
    ReactGA.pageview(window.location.pathname + window.location.search);
  }
  const gds_url = 'https://datastudio.google.com/embed/reporting/1MlLcDHAbNrB99S1U4hha1c8ezTJ8cqZ8/page/H8AKB';
  const gds_mobile_url = 'https://datastudio.google.com/embed/reporting/1sHByr_dS7f8j39GJ5k_tvvHpLpC4a-1z/page/6FeNB';
  //const gds_mobile_url = 'https://datastudio.google.com/embed/reporting/1sunA0AwbQ___B8ZUze1qr3gpBDLmSomK/page/6bQNB';

  
  

  const[fetchedCountyData, setFetchedCountyData] = React.useState(true);
  const[showNotice, setShowNotice] = React.useState(true);
  const[gdsQueryString, setGdsQueryString] = React.useState('');


  const onGeolocationUpdate = (newLoc) =>
  {
    if (newLoc && newLoc.error)
    {
      setFetchedCountyData(true);
      setGdsQueryString('');
    }
    else if (newLoc !== null && fetchedCountyData === false)
    {
      setFetchedCountyData(true);

      fetch(`https://geo.fcc.gov/api/census/area?lat=${newLoc.latitude}&lon=${newLoc.longitude}&format=json`)
      .then(res => res.json())
      .then((data) => {
       console.log('county data', data);
       setCountyData(data.results[0]);
      })
      .catch(console.log)
    }

   // geolocation = newLoc;
    //console.log('newloc', newLoc);
  }

  //let geolocation;
  
  const geolocation = useGeolocation({  
    enableHighAccuracy: false, 
    maximumAge:         Infinity, 
    timeout:            12000
  }, onGeolocationUpdate);
  const [countyData, setCountyData] = React.useState(null);


  //https://datastudio.google.com/reporting/1MlLcDHAbNrB99S1U4hha1c8ezTJ8cqZ8/page/H8AKB?params=%7B%22df28%22:%22include%25EE%2580%25800%25EE%2580%2580IN%25EE%2580%2580Alabama%2520-%2520Autauga%22,%22df51%22:%22include%25EE%2580%25800%25EE%2580%2580IN%25EE%2580%2580Arkansas%22%7D
  //React.useEffect(() => {setGdsQueryString((countyData) ? `params=%7B"df28":"include%25EE%2580%25800%25EE%2580%2580IN%25EE%2580%2580${countyData.state_name}%2520-%2520${countyData.county_name}"%7D` : '')}, [countyData]);
  React.useEffect(() => {setGdsQueryString((countyData) ? `params=%7B%22df28%22:%22include%25EE%2580%25800%25EE%2580%2580IN%25EE%2580%2580${countyData.state_name}%2520-%2520${countyData.county_name}%22,%22df51%22:%22include%25EE%2580%25800%25EE%2580%2580IN%25EE%2580%2580${countyData.state_name}%22%7D` : '')}, [countyData]);

  //React.useEffect(() => console.log('new loca'), [geolocation]);

  
  //const [geolocation, setGeolocation] = React.useState(null);
  //React.useEffect(() => {setGeolocation(props.geolocation); console.log(geolocation)}, [props.geolocation]);

  // const getLocation = () =>
  // {
  //   geolocation = useGeolocation();
  // }


  return (
<div style={{width: '100%', height: '100vh', textAlign: 'center'}}>

          <br/>

      {
          (showNotice === true) &&
            <div style={{textAlign: 'center'}}>
              <div style={{fontStyle: 'italic'}}><span style={{fontWeight: 'bold'}}>NOTICE:</span> This is a work in progress. Please <a href="mailto:chris@algistix.com" style={{textDecoration: 'none'}}>contact me</a> if you spot any errors.</div>
              {
                (isMobileOnly === true) &&
                <div>
                  <br/>
                  <Typography variant="h6" gutterBottom>
                    You are viewing a mobile version of the dashboard.
                    Things will look different and have reduced capability.
                    For the full experience, use a desktop computer.
                  </Typography>
                </div>
              }
              <br/>
              <Button variant='contained' onClick={() => setShowNotice(false)}>Close this Notice</Button>
              <br/>
              <hr/>
            </div>
          
        }
          <br/>
              <a href='https://youtu.be/qHar28_JpkY' target='_blank' style={{paddingTop: 20, textDecoration: 'none'}}>Click Here</a> To See Demonstration Video On How To Use This Dashboard
            
              <br/>
              <br/>

        {
        (isMobileOnly === true)
        ?
          (fetchedCountyData)
          ?
          <iframe width="100%" height="1500" src={`${gds_mobile_url}?${gdsQueryString}`} frameBorder="0"  allowFullScreen></iframe>
          :
          <div>waiting...</div>
        :
          <Container maxWidth="lg" style={{textAlign:'center'}}>
          {/* 
          <Button
            variant="contained"
            color="primary"
            startIcon={<MyLocationIcon />}
          // onClick={() => getLocation()}
          >
            Automatically Determine my Location
          </Button>
          */}
          
    
          {
          
            (fetchedCountyData)
            ?
            <iframe width="1500" height="1750" src={`${gds_url}?${gdsQueryString}`} frameBorder="0"  allowFullScreen></iframe>
            :
            <div>waiting...</div>
          
          }
          <br/>
          <h2>County Map</h2>
          <USMap name="CodeSandbox"  />
        </Container>

      }

<br/>
          <br/>
          <hr  />
          <br/>
          <br/>
          <span style={{fontSize: 24}}>
            Comments or Suggestions? <a href="mailto:chris@algistix.com" style={{textDecoration: 'none'}}>chris@algistix.com</a>
            </span>
          <br/>
          <br/>
          <br/>
        <table style={{width: '100%', fontWeight: 'bold'}}>
            <caption style={{fontWeight: 'bold'}}>Credits</caption>
            <tbody>
                <tr>
                  <td>
                      <a style={{textDecoration: 'none'}} href="https://github.com/nytimes/covid-19-data" target="_blank">The New York Times</a>
                  </td>
                  <td>
                      <a style={{textDecoration: 'none'}}  href="https://covidtracking.com" target="_blank">The COVID Tracking Project</a>
                  </td>
                  <td>
                      <a style={{textDecoration: 'none'}}  href="https://www.census.gov/" target="_blank">U.S. Census Bureau</a>
                  </td>
                  <td>
                      <a style={{textDecoration: 'none'}}  href="https://highcharts.com/" target="_blank">Highcharts</a>
                  </td>
                  <td>
                      <a style={{textDecoration: 'none'}}  href="https://cloud.google.com/" target="_blank">Google Cloud Platform</a>
                  </td>
                </tr>
            </tbody>
        </table>
        <br/>
        <br/>
          <div style={{width: '100%', textAlign: 'center'}}>
            <a href='http://www.algistix.com' title='This dashboard is brought to you by Algistix'><img src='http://www.algistix.com/wp-content/uploads/2018/12/Asset-22.png' width='300' height='100'></img></a>
          </div>
        <br/>
        <br/>


    </div>
  );
}

export default App;
